import React, { useState , useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import RightNav from "../components/right-nav";
import PageContent from "../components/pagecontent";
import CategoryCards from "../components/categorycards";
import "../assets/css/main.css";
import Paginations from '../components/pagination'
const IndexPage = () => {
  // state Hook 

  const [pageNo, setPageNo] = useState(1)
  const data = useStaticQuery(query);

  //  hendle pagination 

  const handlePageChange = (pageNumber) => {

    setPageNo(pageNumber);
  }

  
  useEffect(() => {
    window.scrollTo({top : 0 ,  behaviour: 'smooth ' })
}, [pageNo])


  // How many post yu want on every page 
  let PostPerPage = 15 

  // pagination calculations
  let indexOfLastPost = pageNo * PostPerPage;
  let indexOfFirstPost = indexOfLastPost - PostPerPage;

  // slice post those are seen and those remain 
  let currentPosts = data?.allStrapiPost?.edges?.slice(indexOfFirstPost, indexOfLastPost);
  console.log("currentPosts" , )
  return (
    <div className="main_layout_container">
      <Layout headerText="show">
        <div className="main_container">
          <div className="page_category_main_div">
            <PageContent />
            <div>
              {/* category_cards_main_div */}
              <div className=" category_cards_main_div ">
                {/* card_div */}
                <div className="card_div ">
                  {currentPosts?.map((item) => (
                    <CategoryCards
                      thumbnail_title={item?.node?.title}
                      titleHeading={item?.node?.category?.viewName}
                      date={item?.node?.published_at}
                      catagory={item?.node?.category?.slug}
                      slug={item?.node?.slug}
                      thumbnail={item?.node?.thumbnail}
                    />
                  ))}
                </div>
              </div>

                <div className="pagination__option">
                  <Paginations items={data} pageNo={pageNo} handlePageChange={handlePageChange} />
                </div>
            </div>

          </div>
          <RightNav />
        </div>
      </Layout>
    </div>
  );
};

const query = graphql`
  query {
    allStrapiPost {
      edges {
        node {
          title
          slug
          thumbnail
          category {
            viewName
            name
            slug
          }
          publish_at
        }
      }
    }
  }
`;

export default IndexPage;
